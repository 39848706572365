<template>
  <div class="page4">
    <img class="page4-img" src="../../assets/pages/obj_04.png" alt="">
  </div>
</template>

<script>
export default {
  name: 'page4',
}
</script>

<style scoped lang="scss">
.page4 {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100%;

  .page4-img {
    height: 100%;
  }
}
</style>